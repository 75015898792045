import React from 'react'
import '../Info/info.scss'
import { AiFillFacebook, AiFillInstagram ,AiFillLinkedin} from "react-icons/ai"
import MailOutlineIcon from '@mui/icons-material/MailOutline';


const Info = () => {
  return (
    <>
      <div className="contact">
        <ol>
          <li> <MailOutlineIcon  /> <a href="mailto:info@ands.com.np" target={"blank"} >info@ands.com.np</a> </li>
         
            <div>
         
              <div style={{ animationDelay: "0.3s" }} ><li><a href="https://www.facebook.com/andsconsult" target={"blank"}><AiFillFacebook /> </a></li></div>
              <div style={{ animationDelay: "0.5s" }} > <li><a href="https://www.instagram.com/ands_consulting_group/" target={"blank"}><AiFillInstagram /></a></li></div>
              <div style={{ animationDelay: "0.7s" }} > <li><a href="https://www.linkedin.com/company/digital-shoutout/" target={"blank"}><AiFillLinkedin /></a></li></div>
            </div>

         

        </ol>
      </div>
    </>
  )
}

export default Info