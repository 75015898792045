import {React,useEffect} from 'react'
import "../Style/home.scss"
import MyVideo from "../Assets/video.mp4"
import Box3 from "../components/Services/Box3"
import "../Style/Services.scss"

import AOS from "aos"
import 'aos/dist/aos.css'

const Services = () => {
    useEffect(()=>{
        AOS.init({duration:2000})
        window.scrollTo(0,0)
      },[]);
    

    const Arr1=[
        {
            title:"Audit And Aussarance",
            paragraph:"We maintain excellent relationships with our clients by taking the time to truly understand their business and provide a quality led, risk-focused service. ",
            link:"/services/Auditandassurance"
        },
        {
            title:"Business Services  ",
            paragraph:"There are very few advisers who can provide the full spectrum of corporate finance advice to middle market businesses both public and private – ANDS's corporate finance team can.",
            link:"/services/BusinessServices"
        },
        {
            title:"Corporate Finance  ",
            paragraph:"From fast-growing start-ups to established family businesses and public entities, we help to plan and negotiate the changes associated with growth. ",
            link:"/services/CorporateFinance"
        },
        {
            title:"Dispute Services",
            paragraph:"Maintaining lean, efficient business processes is a critical part of keeping in front of the competition. We can help you to face these challenges, ensuring you are amongst those that continue to prosper into the future.",
           
        },
        {
            title:"Management Consultancy ",
            paragraph:"At ANDS. we provide a spectrum of services to help you on the routines and responsibilities of running your company, managing the business and its money.",
            link:"/services/ManagementConsultancy"
        },
       
        
    ]
  return (
    <>
         <div className="section5" style={{height:"60vh"}}>
           <video loop muted autoPlay="autoplay" playsInline className='back-video'>
            <source src={MyVideo} type="video/mp4" />
           </video>
            <div className="content">
                <h1 data-aos="fade-up">OUR RANGE OF SERVICES</h1>
                <p data-aos="fade-up">Whether serving public sector organizations, owner managed businesses, private individuals or listed companies with overseas operations, our goal is to help our clients achieve their ambitions.</p>
            </div>
        </div>
        <div className="service-box">
            <p>ANDS. provides a range of accountancy and business advisory services to assist you with a full spectrum of advice from compliance to facilitating growth.</p>
            <div >
                <div >
                    {Arr1.map((val,index)=><Box3 key={val.key} id={index} title={val.title} logo={val.logo} paragraph={val.paragraph} link={val.link}/>)}
                </div>
            </div>
        </div>
    </>
      
  )
}

export default Services