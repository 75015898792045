import {React,useEffect, useState} from 'react'
import "../Style/contact.scss"

import AOS from "aos"
import 'aos/dist/aos.css'

import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Contactus = () => {
  useEffect(()=>{
    AOS.init({duration:1000})
    window.scrollTo(0,0)
  },[]);

  const [firstName,setFirstName]=useState('');
  const [lastName,setLastName]=useState('');
  const [email,setEmail]=useState('');
  const [phone,setPhone]=useState('');
  const [message,setMessage]=useState('');
  const [subject,setSubject]=useState('');
 
const checkEmail=(e)=>{

  const phoneRegex=/^\+?\d{10,14}$/;
  const emailRegx=/[a-zA-Z0-9._%+-]+@[a-z0-9*-]+\.[a-z]{2,8}(.[a-z]{2,8}])?/g;

  if(!firstName|| !lastName||!email||!emailRegx.test(email)||!subject || !message||!phoneRegex.test(phone)||phone.length<10 || phone.length>10){
    e.preventDefault();
  }else{
    return true;
  }
}


  const sendEmail =()=>{
    const phoneRegex=/^\+?\d{10,14}$/;
    const emailRegx=/[a-zA-Z0-9._%+-]+@[a-z0-9*-]+\.[a-z]{2,8}(.[a-z]{2,8}])?/g;

    if(!firstName || !lastName){
      return toast.error("Fill the Name")
    }else if(!email){
      return toast.error("Enter the email")
    }else if(!emailRegx.test(email)){
      return toast.error("Enter the valid email")
    }else if(!subject || !message){
      return toast.error("Fill the Message")
    }else if(!phone){
      return toast.error("Enter the Number")
    }else if(!phoneRegex.test(phone)){
      return toast.error("Enter a valid phone number")
    }else if(phone.length<10 || phone.length>10){
      return toast.error("Enter 10 number only")
    }else{
      return true;
    }
  }
  return (
    <>
      <div className="Contact">
        <ToastContainer position='top-center' limit={1}/>
        <div className="contactbox">
        <div className="BOX2" >
              <h1 data-aos="zoom-in">Our Office Address</h1>
              <div className="BOX">
                <h2>Head Office</h2>
                <p>Tinkue,Bhaktapur</p>
              </div>
              <div className="BOX">
                <h2>Phone:</h2>
                <p>9843651620</p>
                <p>9864837989</p>
              </div>
              <div className="BOX">
                <h2>Email:</h2>
                <p>info@ands.com.np</p>
              </div>
            </div>
            <div className="BOX1">
              <h1>We love to hear from you</h1>

              <form className='form_div' action="https://formspree.io/f/moqzzeoj" method='POST' onSubmit={checkEmail}>
                 <input 
                  type="text" 
                  name='firstname' 
                  autoComplete='off'
                  id='firstname' 
                  placeholder="FirstName:" 
                  value={firstName}
                  onChange={(e)=>{setFirstName(e.target.value)}}
                 />
                <input 
                  type="text" 
                  name='lastname' 
                  autoComplete='off' 
                  id='lastname' 
                  placeholder="LastName:"
                  value={lastName}
                  onChange={(e)=>{setLastName(e.target.value)}} />

                <input 
                  type="email" 
                  name='email' 
                  autoComplete='on' 
                  id='email' 
                  placeholder="Your email:"
                  value={email}
                  onChange={(e)=>{setEmail(e.target.value)}} />

                <input 
                  type="text" 
                  name='number' 
                  autoComplete='off' 
                  id='number'
                  placeholder="Your phone:"
                  size={10}
                  value={phone}
                  onChange={(e)=>{setPhone(e.target.value)}}  />
                   
                <input 
                  type="textarea" 
                  rows="10" 
                  cols="50" 
                  name='subject'
                  autoComplete='off' 
                  id='subject' 
                  placeholder="Subject:" 
                  value={subject}
                  onChange={(e)=>{setSubject(e.target.value)}}  />

                <div style={{width:"100%",padding:"10px"}}>
                  <textarea name="message" id="message" 
                  value={message}cols="40" rows="5" placeholder='Message'
                  onChange={(e)=>{setMessage(e.target.value)}} ></textarea>
                  
                </div>

                <div style={{width:"100%",padding:"10px",display:"flex"}}>
                  <span>
                    <button  style={{padding:"8px 20px",border:"none",color:"white",backgroundColor:"#00306E",borderRadius:"5px",fontWeight:"600"}} 
                    type='submit'
                    onClick={sendEmail}>
                     Submit
                    </button>
                  </span>
                </div>
              </form>
            </div>


         
        </div>

        <div className="mapbox">
        <iframe 
        title='map'
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.5170282663626!2d85.4096544!3d27.670410699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1ba8cf31dd5b%3A0xd6d8c2ff03de0a8c!2sThe%20Burger%20House%20and%20Crunchy%20Fried%20Chicken!5e0!3m2!1sen!2snp!4v1673888139736!5m2!1sen!2snp" 
            width="100%" 
            height="350" 
            style={{border:0}}
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade">
         </iframe>
        </div>
      </div>
      
      
    </>
  )
}

export default Contactus