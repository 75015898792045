import React from "react";

const Box1 = (props) => {
  return (
    <>
      <div className=" bg-gradient-to-l from-slate-300 to-slate-100 text-slate-600 border border-slate-300 grid grid-col-2 justify-center p-4 gap-4 rounded-lg shadow-md">
        <div className="box1">
          <div className="img">{props.logo}</div>
          <div className="text">
            <h3>{props.title}</h3>
            <p>{props.paragraph}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Box1;
