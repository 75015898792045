import {React,useEffect} from 'react'
import { Outlet } from 'react-router-dom'

import "../Style/aboutus.scss"
import "../Style/home.scss"
import MyIntro from "../Assets/intro.mp4"
// import Box1 from "../components/Home/Box1"
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import AOS from "aos"
import 'aos/dist/aos.css'

const Aboutus = () => {
  useEffect(()=>{
    AOS.init({duration:1000})
    window.scrollTo(0,0)
  },[]);
  // const aboutArr=[
  //   {
  //       title:"NATIONAL AUDIT LICENSE",
  //       paragraph:"The firm has national audit license of: The Institute of Chartered Accountants of Nepal (ICAN), Babar Mahal, Kathmandu, Nepal"
  //   },
  //   {
  //       title:"APPROVED AUDITOR",
  //       paragraph:"The firm is included in the approved auditor list of the Office of the Auditor General of Nepal, United States Agency for International Development (USAID), Nepal Rastra Bank (central bank), UNDP, UNFPA, SNV/Nepal, DANIDA, DFID, HELVETAS etc."
  //   },
  //   {
  //       title:"INTERNATIONAL ASSOCIATIONS",
  //       paragraph:"We act as ACCA Nepal representative."
  //   }
  // ]

  return (
    <>
      <div className="information">
        <div className="section5" style={{ height: "60vh" }}>
          <video style={{ width: '100%', height: "60vh", objectFit: "cover", padding: "0 5%" }} loop muted autoPlay="autoplay" playsInline className='back-video'>
            <source src={MyIntro} type="video/mp4" />
          </video>
        </div>

        <div className="info">
            <div className="title">
              <p><div style={{textAlign:"center",fontStyle:"italic",fontWeight:"900",color:"#31096e"}}>“Do the business with no stress, we will take care of your finances and compliances.”</div> <br /> With the belief that the key to providing the professional accounting and consulting services is to match them with the client’s specific needs, the firm has been working closely with its clients and has been providing accounting, auditing, taxation, and consulting services.</p>
            </div>
            {/* <div className="info_box" data-aos="zoom-in">
              {aboutArr.map((val,index)=><Box1 key={index} title={val.title} logo={val.logo} paragraph={val.paragraph}/>)}
            </div> */}
            <div className="association">
              <h3>About ANDS (Accounting And Digital Shoutout)</h3>
              <p>Professionalism in service is what the firm strives for. We are a group of persons who are capable of dealing with the actual need of the clients.</p>
              <p>The combination of the firm’s expertise, high standards of services, experience of specialized team members and the energy of individual staff reflects upon the firm’s outstanding performance.The firm emphasizes on the fact that it is extremely important to continually educate themselves in order to improve their knowledge and technical expertise.</p>
              <p>The firm is devoted to integrity in their efforts, in their commitments to clients and their people and in their conduct.</p>
            </div>
            <div className="info_list">
              <div className="list close">
              <LocalPhoneIcon/>
                <p>9840090620 , 9843651620 </p>
              </div>
              <div className="list">
                <MailOutlineIcon/>
                <p > info@ands.com.np</p>
              </div>
            </div>
        </div>
      </div>

      <Outlet/>
    </>
  )
}

export default Aboutus