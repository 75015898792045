import React, { useEffect } from 'react'
import "../Servicestype.scss"

const Internaladuit = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <div className="section">
                <div className="container">
                    <h1>Internal Audit</h1>
                    <p>Internal auditing is an independent, objective assurance and consulting activity designed to add value and improve an organization’s operations. It helps an organization accomplish its objectives by bringing a systematic, disciplined approach to evaluate and improve the effectiveness of risk management, control, and governance processes.</p>
                    <h3>The Challenges for Internal Audit</h3>
                    <p>A strategically orientated and structured internal audit function can play a vital role in assisting the Board of Directors, Audit Committees and management in achieving these business challenges. Internal audit can add value by
                        <ul>
                            <li>Focusing on risk areas through the participation in corporate governance and risk management processes. </li>
                            <li>Evaluating and recommending process improvement opportunities. </li>
                            <li>Researching best practices and making recommendations for change. </li>
                            <li>Monitoring the implementation of management strategic actions. </li>
                            <li>Organising itself as a cost effective business unit. When designing an internal audit function, strategy must drive tactics, not the reverse. </li>
                        </ul>
                    </p>
                    <p>Too often, the internal audit functions respond to immediate tactical needs. In a rush to implement responses, key strategic issues can be overlooked. The result can be a tactical internal audit function in search of a strategy.</p>

                    <h3>Our services are:</h3>
                    <p>
                        <ul>
                            <li><h4>Set-up of Internal Audit Function</h4> Work with management in establishing an internal audit function based on our strategy.</li>
                            <li><h4>Strategic Performance/Quality Assurance Reviews</h4>
                                <ul>
                                    <li>Provide an independent review of existing internal audit function and strategy which include:</li>
                                    <li>Clarifying and validating stakeholders’ expectations of internal audit.</li>
                                    <li>Assessing current internal audit structure, methodologies, resources and capabilities in light of value expectations</li>
                                    <li>Assessing current internal audit structure, methodologies, resources and capabilities in light of value expectations.</li>
                                    <li>Benchmarking against peer group</li>
                                    <li>Identifying opportunities to improve capabilities and processes.</li>
                                    <li>Developing actionable strategic plans to align internal audit with corporate goals.</li>
                                </ul>
                            </li>
                            <li>
                                <h4>Strategic Capability Building and Outsourcing (Full & Partial)</h4>
                                <p>Provide support to existing internal audit functions or provide fully outsourced internal audit services with the principle benefit of:</p>
                                <ul>
                                    <li>Access to experienced Thai and foreign auditors.</li>
                                    <li>Access to technical and industry specialists.</li>
                                    <li>Access to best practice internal audit tools and methodologies.</li>
                                    <li>Flexibilities of costs and budgets.</li>
                                </ul>
                            </li>
                            <li>
                                <h4>Policies and Procedures manuals</h4>
                                <p>Develop or enhance policies and procedures manuals to improve the internal control environment within the organisation.</p>
                            </li>
                            <li>
                                <h4>Cost reduction/Efficiency improvement reviews</h4>
                                <ul>
                                    <li>Assist in enhancing operational efficiency and cost reduction to improve an organisation’s competitiveness by applying our best practices to business processes and controls </li>
                                    <li>We ensure that your systems/processes/people and working practices are ‘aligned’ to your risks and business objectives. </li>
                                    <li>On identifying any areas of weakness, corrective actions are produced and prioritized in order to provide a clear direction and path for management to take.Where an organization already has some of the skills required to complete the process but lacks expertise in a particular area, we can supplement the existing skill-set. </li>
                                    <li>Whether you need an outsourced or co-sourced solution or already have an established internal audit function and simply need specialist support, our experienced internal audit and risk management professionals are ready to work with you to help you enhance your systems of governance, risk, and control.</li>
                                </ul>
                            </li>
                        </ul>
                    </p>
                </div>
            </div>

        </>
    )
}

export default Internaladuit