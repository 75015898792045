import React from "react";
import Logo from "../Assets/Logo1.webp"
import "../Style/navbar.scss"
import Info from "../components/Info/Info"
import { NavLink, Link } from "react-router-dom"
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Navbar = () => {
  return (
    <>
      <header >
        <Info />
        <div className="container-fluid">

          <div className="nav-logo">
            <Link to={"/"}><img src={Logo} alt="Loading..." loading="lazy" /></Link>
          
          </div>

          <div className="navitems d-none d-lg-flex">
            <div className="item">
              <NavLink to={"/"}>Home</NavLink>
            </div>

            <div className="item">
              <NavLink to={"/aboutus"}>About us</NavLink>
            </div>

            <div className="right-menu">
              <button className="menu-button"><NavLink to="/services"> Services <ArrowDropDownIcon /></NavLink></button>

              <ul className="dropdown-menu " style={{ border: "none", boxShadow: "0 5px 10px -5px black" }}>
                <div className="grid-container">
                  <div className="grid-item">
                    <button className="submenu-button ">
                      <NavLink to={"/services/Auditandassurance"}>Audit and Assurance <ArrowRightIcon /></NavLink>
                    </button>
                    <ul className="dropdown-submenu" >
                      <li><NavLink to={'/services/Auditandassurance/InternalAudit'}>Internal Audit</NavLink></li>
                      <li><NavLink to={'/services/Auditandassurance/Nasreporting'}>NAS Reporting</NavLink></li>
                    </ul>
                  </div>

                  <div className="grid-item">
                    <button className="submenu-button" >
                      <NavLink to="/services/BusinessServices">Business Services <ArrowRightIcon /></NavLink>

                    </button>
                    <ul className="dropdown-submenu">
                      <li><NavLink to={'/services/BusinessServices/ManagementAccounting'}>Management Accounting</NavLink></li>
                      <li><NavLink to={'/services/BusinessServices/Payroll'}>Payroll Services</NavLink></li>
                    </ul>
                  </div>


                  <div className="grid-item">
                    <button className="submenu-button ">

                      <NavLink to="/services/CorporateFinance"> Corporate Finance <ArrowRightIcon /></NavLink>
                    </button>
                    <ul className="dropdown-submenu" >
                      <li><NavLink to={'/services/CorporateFinance/DueDiligence'}>Due Diligence</NavLink></li>
                      <li><NavLink to={"/services/CorporateFinance/ProjectFinanceAndFinancialModeling"}>Project Finance and Financial Modeling</NavLink></li>
                    </ul>
                  </div>

                  <div className="grid-item">
                    <button className="submenu-button" >
                      <NavLink to="/services/ManagementConsultancy">Management Consultancy </NavLink>
                    </button>
                   
                  </div>

                  <div className="grid-item">
                    <button className="submenu-button">
                      <li className="notneeded">Dispute Services <ArrowRightIcon /></li>
                    </button>
                    <ul className="dropdown-submenu" >
                      <li className="notneeded">Liquidation/Closure</li>
                      <li className="notneeded"> Investigation</li>
                    </ul>
                  </div>

                </div>
              </ul>
            </div>



            <div className="item">
              <NavLink to={"/ourclients"}>Our Client</NavLink>
            </div>

            <div className="item">
              <NavLink to={"/contact"}>Contact us</NavLink>
            </div>


          </div>







          {/* <!-- Button trigger modal --> */}
          <div className="mobile-toggle d-lg-none" >
            <Link data-bs-toggle="modal" data-bs-target="#navModal"><MenuIcon /></Link>
          </div>

          {/* <!-- Modal --> */}
          <div className="modal fade d-lg-none" id="navModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                    <h1 className="d-none d-lg-flex" data-bs-toggle="modal" data-bs-target="#navModal"><Link to={"/"}> Accounting and Digital Shoutout</Link> </h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><CloseIcon /></button>
                </div>
                <div className="modal-body">
                  <div className="modal-line" data-bs-toggle="modal" data-bs-target="#navModal" >
                    <NavLink to={"/"}>HOME</NavLink>
                  </div>
                  <div className="modal-line" >
                    <div className="servicestitle" >
                      <NavLink> SERVICES</NavLink>
                    </div>
                    <div className="sub-menu-1">
                      <ul>

                        <li data-bs-toggle="modal" data-bs-target="#navModal"> <Link to={"/services/ManagementConsultancy"}> Management Consultancy </Link>
                        
                        </li>

                        <li data-bs-toggle="modal" data-bs-target="#navModal">
                          <Link to={"/services/Auditandassurance"}> Audit and Assurance  <ArrowDropDownIcon /> </Link>
                          <div className="sub-menu-2">
                            <ul>
                              <li data-bs-toggle="modal" data-bs-target="#navModal" ><Link to={'/services/Auditandassurance/InternalAudit'}>Internal Audit</Link></li>
                              <li data-bs-toggle="modal" data-bs-target="#navModal" ><Link to={'/services/Auditandassurance/Nasreporting'}>NAS Reporting</Link></li>
                            </ul>
                          </div>
                        </li>

                        <li data-bs-toggle="modal" data-bs-target="#navModal"><Link to={"/services/BusinessServices"}>Business Services  <ArrowDropDownIcon /></Link>
                          <div className="sub-menu-2">
                            <ul>
                              <li data-bs-toggle="modal" data-bs-target="#navModal" ><Link to={'/services/BusinessServices/ManagementAccounting'}>Management Accounting</Link></li>
                              <li data-bs-toggle="modal" data-bs-target="#navModal" ><Link to={'/services/BusinessServices/Payroll'}>Payroll Services</Link></li>
                            </ul>
                          </div>
                        </li>

                        <li data-bs-toggle="modal" data-bs-target="#navModal"><Link to={"/services/CorporateFinance"}>Corporate Finance  <ArrowDropDownIcon /></Link>
                          <div className="sub-menu-2">
                            <ul>
                              <li data-bs-toggle="modal" data-bs-target="#navModal"><Link to={'/services/CorporateFinance/DueDiligence'}>Due Diligence</Link></li>
                              <li data-bs-toggle="modal" data-bs-target="#navModal"><Link to={"/services/CorporateFinance/ProjectFinanceAndFinancialModeling"}>Project Finance and Financial Modeling</Link></li>
                            </ul>
                          </div>
                        </li>


                        <li> Dispute Services <ArrowDropDownIcon />
                          <div className="sub-menu-2">
                            <ul>
                              <li className="notneeded">Liquidation/Closure</li>
                              <li className="notneeded">Investigation</li>
                            </ul>
                          </div>
                        </li>


                      </ul>
                    </div>
                  </div>



                  <div className="modal-line" data-bs-toggle="modal" data-bs-target="#navModal">
                    <NavLink to={"/aboutus"}>ABOUT US</NavLink>
                  </div>

                  <div className="modal-line" data-bs-toggle="modal" data-bs-target="#navModal">
                    <NavLink to={"/ourclients"}>OUR CLIENTS</NavLink>
                  </div>

                  <div className="modal-line" data-bs-toggle="modal" data-bs-target="#navModal">
                    <NavLink to={"/contact"}>CONTACT US</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>


    </>
  )
}

export default Navbar;