import React from 'react'
import {BrowserRouter as Router,Routes,Route} from "react-router-dom"

import "./App.scss"
import MainBody from './Main/Home/MainBody'
import ServiceBody from './Main/Services/ServiceBody'
import Servicesubbody from './components/Services/Servicesubbody'

import Home from "./Main/Home"
import Contactus from './Main/Contactus'
import Services from './Main/Services'
import Aboutus from './Main/Aboutus'
import OurClients from './Main/OurClients'
import Error from './Main/Error'

import Auditandaussarance from './components/Services/Type/Auditandaussarance'
import BusinessServices from './components/Services/Type/BusinessServices'
import CorporateFinance from './components/Services/Type/CorporateFinance'
import ManagementConsultancy from './components/Services/Type/ManagementConsultancy'


import Internaladuit from './components/Services/Type/Subtype/Internaladuit'
import NASreporting from './components/Services/Type/Subtype/NASreporting'
import ManagementAccounting from './components/Services/Type/Subtype/ManagementAccounting'
import Payroll from './components/Services/Type/Subtype/Payroll'
import Duediligence from './components/Services/Type/Subtype/Duediligence'
import ProjectFinancing from './components/Services/Type/Subtype/ProjectFinancing'



const App = () => {
  return (
    <Router>
      <Routes>
        {/* Nested Routing and Shared Layout */}
        <Route path="/" element={<MainBody/>}>
          <Route index element={<Home/>}/>
          <Route path="/contact" element={<Contactus/>}/>
          <Route path='/services' element={<ServiceBody/>}>
            <Route index element={<Services/>}/>

            <Route path='/services/Auditandassurance' element={<Servicesubbody/>}>
              <Route index element={<Auditandaussarance/>}/>
              <Route path='/services/Auditandassurance/InternalAudit' element={<Internaladuit/>}/>
              <Route path='/services/Auditandassurance/Nasreporting' element={<NASreporting/>}/>
            </Route>
            
            <Route path='/services/BusinessServices' element={<Servicesubbody/>}>
              <Route index element={<BusinessServices/>}/>
              <Route path='/services/BusinessServices/ManagementAccounting' element={<ManagementAccounting/>}/>
              <Route path='/services/BusinessServices/Payroll' element={<Payroll/>}/>
            </Route>

            <Route path='/services/CorporateFinance' element={<Servicesubbody/>} >
              <Route index element={<CorporateFinance/>}/>
              <Route path='/services/CorporateFinance/DueDiligence' element={<Duediligence/>} />
              <Route path='/services/CorporateFinance/ProjectFinanceAndFinancialModeling' element={<ProjectFinancing/>} />
              <Route/>
             
            </Route>

            <Route path='/services/ManagementConsultancy' element={<ManagementConsultancy/>} />  
          </Route>
          <Route path='/ourclients' element={<OurClients/>}/>
          <Route path='/aboutus' element={<Aboutus/>} />
          <Route path='*' element={<Error/>}/>
        </Route>
      </Routes >
    </Router>
  )
}

export default App