import React, { useEffect } from 'react'
import Box3 from '../Box3'
import "../Type/Servicestype.scss"

const CorporateFinance = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const Arr1 = [
    {
      title: "Project finance and financial modeling",
      paragraph: "ANDS. has developed an excellent team and first-rate service line in project finance which works closely with the firm’s financial modeling offering and associated advisory team.",
      link: "/services/CorporateFinance/ProjectFinanceAndFinancialModeling"
    },
    {
      title: "Due diligence",
      paragraph: "Without a doubt, the success of acquisition to an acquirer can be significantly influenced by the extent and quality of due diligence undertaken. Financial due diligence is a key aspect of the overall investigation into the target.",
      link: '/services/CorporateFinance/DueDiligence'
    },
  ]
  return (
    <>
      <div className="section">
        <div className="container">
          <h1>Corporate finance</h1>
          <p>There are very few advisers who can provide the full spectrum of corporate finance advice to middle-market businesses both public and private – PP Pradhan & Co’s Corporate Finance team can.  <br /> As a leading accounting firm, our Due Diligence team, backed up by our taxation and pensions specialists, provide due diligence to acquisitive companies, private equity investors, and banks.Allied to these teams we have specialist Valuations and Financial Modelling teams that both provide essential support to our growing clients. <br />Find out more about us and the clients we’ve cared for or call one of our service heads to find out more. </p>
        </div>
        <div className="service-box">
          <div >
            <div style={{ flexDirection: "column" }} >
              <div className='box1'> <h1>Our Services</h1></div>
             
              {Arr1.map((val,index) => <Box3 key={val.key} id={index} title={val.title} logo={val.logo} paragraph={val.paragraph} link={val.link} />)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CorporateFinance