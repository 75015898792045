import React, { useEffect } from 'react'
import "../Servicestype.scss"

const Duediligence = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

  return (
    <>
        <div className="section">
                <div className="container">
                    <h1>Due Diligence</h1>
                    <p>Due diligence can make the difference between a successful corporate marriage and a miserable one—and between stellar and poor performance in a private equity portfolio. A.N.D.S’s strategic due diligence provides corporate acquirers and private equity firms with maximum insight into their targets so they can make the right decision</p>
                    <p>Due Diligence is a type of project when consultants evaluate the company and its opportunities for growth for a potential Merger & Acquisition deal. This includes market analysis, company analysis, valuation, potential synergies, etc. Without doubt the success of an acquisition to an acquirer – be it a corporate or financial investor – can be significantly influenced by the extent and quality of due diligence undertaken. Financial due diligence is a key aspect of the overall investigation into the target.</p>
                </div>
            </div>

    </>
  )
}

export default Duediligence