import React, { useEffect } from 'react'
// import "../Type/Servicestype.scss"
import Box3 from '../Box3'

const Auditandaussarance = () => {

  useEffect(() => {
  window.scrollTo(0, 0)
  }, []);
  
  const Arr1 = [
    {
      title: "NAS reporting",
      paragraph: "The complexities of converting to NAS pose a considerable drain on time and resources for any financial department, requiring potentially hundreds of hours of work to deal effectively with all matters arising. We’re here to help free your time. ",
      link: "/services/Auditandassurance/Nasreporting"
    },
    {
      title: "Internal audit",
      paragraph: "Our expert auditors take a risk-based approach, identifying the areas that are of most risk to an organization’s operational effectiveness.",
      link: "/services/Auditandassurance/InternalAudit"
    },
  ]
  return (
    <>
      <div className="section">
        <div className="container">
          <h1>Audit Assurance</h1>
          <p>An audit is much more than a formality; it’s a real necessity to protect a business and encourage it to thrive. Early warnings, open communication and pragmatic resolution of issues are all essential features of our approach.<br />We maintain excellent relationships with our clients by taking the time to truly understand their business and provide a quality led, risk-focused service.</p>
        </div>
        <div className="service-box">
          <div >
            <div style={{ flexDirection: "column" }} >
            <div className='box1'> <h1>Our Services</h1></div>
              {Arr1.map((val,index) => <Box3 key={val.key} id={index} title={val.title} logo={val.logo} paragraph={val.paragraph} link={val.link} />)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Auditandaussarance;