import React, { useEffect } from 'react'
import "../Servicestype.scss"


const ProjectFinancing = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <div className="section">
                <div className="container">
                    <h1>Project Financing and Finance Modeling</h1>
                    <p>
                        Project finance is the funding (financing) of long-term infrastructure, industrial projects, and public services using a non-recourse or limited recourse financial structure. The debt and equity used to finance the project are paid back from the cash flow generated by the project.
                    </p>
                    <p>
                        Project financing is a loan structure that relies primarily on the project's cash flow for repayment, with the project's assets, rights, and interests held as secondary collateral. Project finance is especially attractive to the private sector because companies can fund major projects off-balance sheet (OBS).
                    </p>
                    <p>ANDS helps business organization in the project financing and finance modeling from banks and financial institution for growing the business.</p>
                </div>
            </div>
        </>
    )
}

export default ProjectFinancing