import React from 'react'
import { Link } from 'react-router-dom';

const Box3 = (props) => {
  return (
   <>
    <div className="box1">
            <div className="text">
              <div style={{display:'flex',alignItems:"center"}}>
              {props.id+1}) <h3>{props.title}</h3>
              </div>
                <p>{props.paragraph} <button><Link to={props.link}>Read More</Link></button></p>
            </div>
        </div>
   </>
  )
}

export default Box3