import React, { useEffect } from 'react'
import Box3 from '../components/Services/Box3';
import "../Style/ourclient.scss"

const OurClients = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

      const Arr1 = [
        {
          title: "FMCG ",
          paragraph: "Fast-moving consumer goods are products that sell quickly at relatively low cost. These goods are also called consumer packaged goods. The Nepal FMCG sector is the largest manufacturing sector in the country accounting for 14% of all manufacturing. Our clients include the supply of beverage, liquors (Domestic & International), toiletries items and include the supply of products of large industries in Nepal such as Dabur Nepal Pvt. Ltd., JGI Pvt. Ltd., Unilever Nepal, etc.",
        },
        {
          title: "Banks, Financial Institutions and Investment",
          paragraph: "As financial services companies seek opportunities for growth, innovation and revenue, they must face risk, comply with regulations and vie for new customers in an evolving marketplace. Our clients include various cooperative societies in the Kathmandu valley.",
        },
        {
            title: "Real Estate and Construction",
            paragraph: "The real estate and construction sector is increasingly influenced by rapid technological advancements and significant demographic shifts, including growing urbanization, longevity of baby boomers, and differentiated lifestyle patterns of millennials. In addition, macroeconomic and regulatory developments continue to affect profitability. Executives have their hands full as they look to find ways to respond to these changes, gain a competitive advantage, and drive top- and bottom-line growth.",
          },
          {
            title: "Tech and EV",
            paragraph: "There is a need for conveniently accessible charging points for the charging infrastructure to support the Nepalese electric vehicle market. As per reports, there are one million public charging points in the world and not only 0.1% are in Nepal. and this could be a great Business Opportunity to work in EVs Sector. Our clients include the company which supply and establish various charging stations overall the nation."
          },
      ]


    return (
        <>
            <div className="section">
                <div className="container">
                    <h1>Our Clients</h1>
                </div>
                <div className="service-box">
                    <div >
                        <div style={{ flexDirection: "column" }} >
                           
                            {Arr1.map((val,index) => <Box3 key={val.key} id={index} title={val.title} logo={val.logo} paragraph={val.paragraph} link={val.link} />)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurClients