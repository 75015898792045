import React from 'react'
import { Outlet} from 'react-router-dom';

const Servicesubbody = () => {
  return (
    <>
    <Outlet/>
    </>
  )
}

export default Servicesubbody;