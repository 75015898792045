import React, { useEffect } from 'react'
import "../Servicestype.scss"

const NASreporting = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

  return (
    <>
     <div className="section">
        <div className="container">
            <h1>NAS Reporting</h1>
            <p>Under the Nepal Chartered Accountants Act, 1997 (as amended), Nepal Accounting Standards (NASs) are developed by the Accounting Standards Board (ASB), on the basis of relevant International Financial Reporting Standards (IFRSs).  In developing standards, the ASB takes into consideration the legal and regulatory environment and the preparedness of stakeholders in Nepal, with the objective of making the fewest possible modifications to IFRSs. <br /> <br /> 
            Ethical rules governing auditors allow them to offer clients education and advice on NAS. However, A.N.D.S can manage the complete conversion process for its non-audit clients which will ensure that you have the resource you need, for the period that you need it. <br /> <br />We offer a four-stage process for clients requiring NAS conversion. This includes:
                <ul>
                    <li>Preliminary fact assessment</li>
                    <li>detailed planning</li>
                    <li>implementation</li>
                    <li>post-implementation review.</li>
                </ul>
            <br /> Our expert advisors can introduce and explain the key points in a workshop lasting just a few hours. <br /> <br />Whether it be describing the differences in reconciliations of published accounts or providing a clear audit trail for boards and auditors, ands conversion tools and proven methodology can ensure a smooth and painless transition to NAS.


            </p>
        </div>
    </div>
    </>
  )
}

export default NASreporting