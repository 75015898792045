import React, { useEffect } from 'react'

import "../Type/Servicestype.scss"

const ManagementConsultancy = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <div className="section">
        <div className="container">
          <h1>Management consultancy</h1>
          <p> <strong>Management consulting</strong>  is the practice of helping organizations to improve their performance, primarily through the analysis of existing organizational problems and development of plans for improvement. Organizations may draw upon the services of ANDS management consultants for a number of reasons, including gaining external (and presumably objective) advice and access to the consultants’ specialized expertise.
            <br />As a result of their exposure to and relationships with numerous organizations, ANDS is also said to be aware of industry “best practices”, although the transferability of such practices from one organization to another may be limited by the specific nature of situation under consideration.</p>
        </div>
        <div className="service-box">
          <div >
            <div style={{ flexDirection: "column" }} >
              <div className='box1'>
                <h1>Our Services</h1>
              </div>

              <div className="box1">
                <div className="text">
                  <h3>Outsourcing</h3>
                  <p>The back office may be business critical, but it’s hardly inspirational. It distracts your focus from what you should be doing; increasing growth and maximising profits. ANDS, challenges the way back office services have traditionally been supplied allowing you to concentrate on growing your business.</p>
                </div>
              </div>

              <div className="box1">
                <div className="text">
                  <h3>Taxation</h3>
                  <p>There will frequently be several facets to every tax decision. To see how the questions around tax all fit together, ask ANDS.We have experience, knowledge, and understanding of taxation and appreciate the needs of both business and personal clients.</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default ManagementConsultancy