import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import "../Style/footer.scss"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useState } from 'react';

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);


  // FOR SCROLLING
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }


  //FOR SHOWING THE SCROLLING ARROW
  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll)
  }, [])


  const listenToScroll = () => {
    let heightTOHidden = 250;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    if (winScroll > heightTOHidden) {
      setIsVisible(true)
    } else {
      setIsVisible(false);
    }
  }



  return (
    <>
      <div className='footer'>
        <div id="horizontal-line"></div>

        <div className="Container">
          <div className="container_box">
            <h2>Company Address</h2>
            <h4>Address: <li> <p>Tinkune,Bhaktapur</p> </li></h4>
            <h4>Phone:<li> <a href="tel: 9843651620"> +977-9843651620</a> </li> <li> <a href="tel: 9864837989"> +977-9864837989</a></li> 
            </h4>
          </div>
          <div className="container_box">
            <h2>Our Services</h2>
            <div className="grid">
              <p> <Link to="/">Home</Link> </p>
              <p><Link to="/aboutus">About Us</Link></p>
              <p><Link to="/services">Services</Link></p>
              <p><Link to="/contact">Contact Us</Link></p>
            </div>


            {isVisible && (
              <div className="scrollTop float-right">
                <ArrowUpwardIcon onClick={gotoTop} />
              </div>
            )}



          </div>


          <div className="container_box">
            <h2>Quick Links:</h2>
            <div className="grid">
              <p> <a href="https://ird.gov.np/" target='_blank' rel="noreferrer">IRD</a></p>
              <p> <a href="https://ocr.gov.np/" target='_blank' rel="noreferrer">OCR</a></p>
              <p> <a href="https://www.nrb.org.np/" target='_blank' rel="noreferrer">NRB</a></p>
              <p> <a href="https://www.onlinekhabar.com/" target='_blank' rel="noreferrer">Online Khabar</a></p>
              <p> <a href="https://nepalstock.com/" target='_blank' rel="noreferrer">Nepal Stock</a></p>
            </div>
          </div>


          <div className="container_box">
            <h2>Useful Links & Info:</h2>
            <div className="grid">
              <p> <a href="https://connectips.com//" target='_blank' rel="noreferrer">Connect IPS</a></p>
              <p> <a href="https://www.ilovepdf.com/jpg_to_pdf" target='_blank' rel="noreferrer">Image Converter</a></p>
              <p> <a href="https://meroshare.cdsc.com.np/#/login" target='_blank' rel="noreferrer">Mero Share</a></p>
              <p> <a href="https://www.hamropatro.com/date-converter" target='_blank' rel="noreferrer">Date Converter</a></p>
            </div>
          </div>
        </div>


        <div className="Container">
          <p>Copyright © 2022 | Powered by ANDS | All Rights Reserved</p>
        </div>
      </div>


    </>
  )

}

export default Footer