import React, { useEffect } from 'react'
import "../Servicestype.scss"

const ManagementAccounting = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
  return (
    <>
        <div className="section">
            <div className="container">
                <h1>Management Accounting</h1>
                <p>It may be a cliche, but the fasted thing that happens when you’re running a business is that you end up working in it, not on it. Daily pressures mean that it’s easy to lose sight of where your most profits lie, who you should be targeting, and where you could be cutting costs. Yet in both good times and in bad, success in business relies upon having the best information available and maintaining a connection with a clear strategic plan. At ANDS, we offer you the experience and guidance to help you make effective decisions with confidence so you can meet your goals and exceed expectations.</p>
                <p>We have experience across many areas of business and accounting including auditing, financial management, book keeping and more. This means that A.N.D.S can provide you with management accounting and business advisory services including:
                    <ul>
                        <li>Budget preparation</li>
                        <li>Target area based management planning</li>
                        <li>Weekly or periodic business monitoring</li>
                        <li>Periodic business performance reports</li>
                    </ul>
                </p>
                <p>In addition to the above business services, we also offer:
                    <ul>
                        <li>Management review meetings</li>
                        <li>Cashbook management</li>
                        <li>Cashflow projections and plannings</li>
                        <li>General management accounting and monitoring</li>
                        <li>Break even analysis and reports</li>
                    </ul>
                </p>
                <p>Management accounting from A.N.D.S  is a highly effective method of clarifying your goals and current position whilst also gaining valuable insight from an external party who has your best interests in mind. Many of our clients find this alternative perspective highly valuable when tackling tasks and projects both small and large</p>
            </div>
        </div>
    </>
  )
}

export default ManagementAccounting