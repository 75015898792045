import React, { useEffect } from 'react'
import "../Servicestype.scss"

const Payroll = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <div className="section">
                <div className="container">
                    <h1>Payroll Services</h1>
                    <p>Human Resources management is a crucial job for every organization. In competitive business environment, organization should focus on their core business operation and function and outsource its support service like HR Support, Payroll to business consultancy firm to avoid unnecessary hassle.<br />A.N.D.S provide HR support service and payroll service to any organization as per their requirement along with associated legal compliance. Our HR Support services are:
                    <ul>
                        <li>Recruitment Services</li>
                        <li>Payroll Processing, SSF, PF, CIT</li>
                        <li>Retirement Plans and its Impact on Business </li>
                        <li>HR Policy and Procedure</li>
                        <li>Legal Compliance</li>
                    </ul>
                    </p>
                </div>
            </div>

        </>
    )
}

export default Payroll