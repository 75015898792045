import React, { useEffect } from 'react'
import Box3 from '../Box3'
import "../Type/Servicestype.scss"

const BusinessServices = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const Arr1 = [
    {
      title: "Management accounting",
      paragraph: "Advisers from ANDS. can put a set of figures into perspective. We’ll be able to discuss them with you to determine what the management accounts actually say about the state of your business.",
      link: "/services/BusinessServices/ManagementAccounting"
    },
    {
      title: "Payroll",
      paragraph: "ANDS. can offer a fully comprehensive payroll service unique to each client’s individual needs.",
      link: "/services/BusinessServices/Payroll"
    },
    // {
    //   title: "Management of accounts",
    //   paragraph: "ANDS. can prepare your statutory accounts to assist directors in fulfilling their obligations to report on the financial position of the business.",
    //   link: ""
    // },
  ]
  return (
    <>
      <div className="section">
        <div className="container">
          <h1>Business Services</h1>
          <p>At ANDS, we provide a spectrum of services to help you with the routines and responsibilities of running your company, managing the business, and its money.</p>
         
          <p>This section demonstrates our day-to-day service offerings:
            <ul>
              <li>management accounting</li>
              <li>payroll</li>
              <li>preparation of accounts.</li>
            </ul>
          </p>
     
          <p>We can take on and manage these aspects of the business – and more – for you, allowing you time to do what you do best.We pride ourselves on providing reliable and responsible business-support services and nothing pleases us more than seeing our clients’ businesses succeed.</p>
        </div>
        <div className="service-box">
          <div >
            <div style={{ flexDirection: "column" }} >
            <div className='box1'> <h1>Our Services</h1></div>
              {Arr1.map((val,index) => <Box3 key={val.key} title={val.title} id={index} logo={val.logo} paragraph={val.paragraph} link={val.link} />)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BusinessServices