
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import "../Style/home.scss"
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SettingsIcon from '@mui/icons-material/Settings'


import AOS from "aos"
import 'aos/dist/aos.css'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay } from "swiper";
import { Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar"
import "swiper/css/autoplay"
import "swiper/css/pagination";




import imge1 from "../Assets/scrollimg1.webp"
import imge2 from "../Assets/image.webp"
import section2 from "../Assets/section2.webp"
import Sec3Img from "../Assets/sec3.webp"
import teamImage from "../Assets/teamImage.webp"

import Box1 from '../components/Home/Box1';
import GridBox2 from "../components/Home/Box2"

import MyVideo from "../Assets/video.mp4"
// import Popup from "../components/Popup"

const Section = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 })
        window.scrollTo(0, 0)
    }, []);


    const navigate = useNavigate();
    const gotoServices = () => {
        navigate("/services")
    }


    const section1Arr = [
        {
            key: "1",
            logo: <LightbulbCircleIcon />,
            title: "INTERNAL AUDIT",
            paragraph: "Internal audits evaluate a company’s internal controls, including its corporate governance and accounting processes. These types of audits ensure compliance with laws and regulations and help to maintain accurate and timely financial reporting and data collection. "
        },
        {
            key: "2",
            logo: < NewspaperIcon />,
            title: "TAXATION",
            paragraph: "There will frequently be several facets to every tax decision. To see how the questions around tax all fit together, ask ANDS. We have experience, knowledge and understanding of taxation and appreciate the needs of both business and personal clients."
        },
        {
            key: "3",
            logo: <SettingsIcon />,
            title: "ADVISORY",
            paragraph: "You don’t need consultants to tell you what you’re trying to accomplish or why. You need help figuring out how to get there. Whether you need to streamline operations, integrate acquisitions, perform investigations, convert to new systems or more."
        },
    ]


    return (
        <>
            <div className="home" id='home'>
                <main>
                    <div className="size">
                        <div className="section">
                            <Swiper className="mySwiper"
                                direction={"vertical"}
                                slidesPerView={1}
                                modules={[Autoplay]}
                                autoplay={
                                    {
                                        delay: 5000,
                                        disableOnInteraction: false
                                    }
                                }
                            >
                                <SwiperSlide>
                                    <img src={imge2} loading="lazy" alt="loadimage" />
                                    <div>
                                        <h1 className="top">
                                            Together
                                        </h1>
                                        <h3 className='bottom' >
                                            We Grow
                                        </h3>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={imge1} loading="lazy" alt="loadimage" />
                                    <div>
                                        <h1 className="top">
                                            Do What
                                        </h1>
                                        <h3 className='bottom' >
                                            Moves You
                                        </h3>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </main>
            </div>




            <div className='section1'>
                <div className='Infobox' style={{ width: "70%", textAlign: "center" }}>
                    <h3 data-aos="fade-up" id='heading' style={{ textAlign: "center", marginBottom: "20px" }}> WELCOME TO ANDS CONSULTING GROUP, ONE OF THE GROWING ACCOUNTANCY AND CONSULTING FIRM IN NEPAL</h3>
                    <div className='Infosec' data-aos="zoom-in" style={{ textAlign: "center" }}>
                        {section1Arr.map((val) => <Box1 key={val.key} title={val.title} logo={val.logo} paragraph={val.paragraph} />
                        )}
                    </div>
                </div>
            </div>






            <div className='section2'>
                <div className="imagebox">
                    <img src={section2} alt="" />
                </div>
                <div className="textbox">
                    <div style={{ textAlign: "center" }}>
                        <h3 data-aos="fade-up" id='heading' style={{ textAlign: "center", marginBottom: "30px" }}><strong> Doing the right thing, at the right time.</strong></h3>
                        <div data-aos="zoom-in" style={{ textAlign: "center" }}>
                            <Swiper className="mySwiper"
                                modules={[Pagination, Autoplay]}
                                autoplay={{delay:3000,
                                disableOnInteraction:false}}
                                spaceBetween={50}
                                slidesPerView={1}
                                pagination={true}


                            >
                                <SwiperSlide className='Swiperslide'>
                                    <div className="box1">
                                        <div className="img">
                                            <LightbulbCircleIcon />
                                        </div>
                                        <div className="text">
                                            <h3>Internal Audit Services</h3>
                                            <p>We apply a structured approach to help you assess your risk and improve your risk management capabilities. The firm with a deep understanding of the requirements and compliance needs of highly regulated clients, our dedicated professionals employ best practice methodologies in our Internal Audit.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='Swiperslide'>
                                    <div className="box1">
                                        <div className="img">
                                            < NewspaperIcon />
                                        </div>
                                        <div className="text">
                                            <h3>Statutory Financial Compliance</h3>
                                            <p>There will frequently be several facets to every tax decision. To see how the questions around tax all fit together, ask ANDS. We have experience, knowledge and understanding of taxation and appreciate the needs of both business and personal clients.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='Swiperslide'>
                                    <div className="box1">
                                        <div className="img">
                                            <SettingsIcon />
                                        </div>
                                        <div className="text">
                                            <h3>Attestation Services</h3>
                                            <p>You don’t need consultants to tell you what you’re trying to accomplish or why. You need help figuring out how to get there. Whether you need to streamline operations, integrate acquisitions, perform investigations, convert to new systems or more.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>

                        </div>
                    </div>
                </div>
            </div>


            <div className="section3" >
                <div className="middle">
                    <div className="row">
                        <div className="col imgbox">
                            <img src={Sec3Img} loading="lazy" alt="imgbox" />
                        </div>
                        <div className="col text" >
                            <h1>Our Team</h1>
                            <p data-aos="fade-left">We are a group of persons who are capable of dealing with the actual need of the clients.
                                The combination of the firm’s expertise, high standards of services, experience of specialized team members and the energy of individual staff reflects upon the firm’s outstanding performance.The firm emphasizes on the fact that it is extremely important to continually educate themselves in order to improve their knowledge and technical expertise. <br />
                                The firm is devoted to integrity in their efforts, in their commitments to clients and their people and in their conduct.</p>
                        </div>
                        <div className="w-100"></div>
                        <div className="col text">
                            <h1>Our Philosophy</h1>
                            <p data-aos="fade-right">We have experience of providing expert financial advice for both businesses and individuals. We make this belief a reality by putting clients first, leading with exceptional ideas, doing the right thing, and giving back. Our expertise bring experience in driving our internal audit approach, management services, and financial advice. The team ensures our services are best in class, combining substantiated research with the most effective technology tools available today.</p>
                        </div>
                        <div className="col imgbox" >
                            <img src={teamImage} loading="lazy" alt="imgbox" />
                        </div>
                    </div>
                </div>
            </div>


            <div className="section4">
                <h1 data-aos="fade-left">Range Of Clients</h1>
                <GridBox2 />
            </div>


            <div className="section5">
                
                <video loop muted autoPlay="autoplay" playsInline className='back-video'>
                    <source src={MyVideo} type="video/mp4" />
                </video>

                <div className="content">
                    <h1>WELCOME TO ANDS</h1>
                    <button onClick={() => gotoServices()}>Read More</button>
                </div>
            </div>
        </>
    )
}

export default Section