import React from 'react'
import { Outlet} from 'react-router-dom';

const ServiceBody = () => {
  return (
    <>
    <Outlet/>
    </>
  )
}

export default ServiceBody